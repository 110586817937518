<template>
  <div class="YouTubeWidget">
    <div v-if="isFetching" class="LoadingWrapper VideoList">
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
      <vcl-twitch />
    </div>
    <ul v-if="!isFetching" class="VideoList">
      <li
        v-for="(item, index) in items"
        :key="index"
        :data-item="index"
        :class="`VideoItem L${index}`"
      >
        <h5 class="VideoTitle">
          {{ item.title }}
        </h5>
        <youtube
          :id="item.id"
          :ref="`youtube-${item.id}`"
          :player-vars="{ resize: true, fitParent: true }"
          :video-id="item.id"
          @playing="playing"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { VclTwitch } from 'vue-content-loading';

export default {
  components: {
    VclTwitch,
  },
  props: {
    isFetching: {
      type: Boolean,
      value: true,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      hideTitle: [],
      isPlaying: null,
    };
  },
  mounted() {
    // const tag = document.createElement('script');
    // tag.src = 'https://www.youtube.com/iframe_api';
    // const firstScriptTag = document.getElementsByTagName('script')[0];
    // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    // window.onYouTubeIframeAPIReady = () => {
    //   this.items.forEach((item) => {
    //     this.players[item.id] = new window.YT.Player(item.id, {
    //       events: {
    //         onStateChange: this.onPlayerChange(item.id),
    //       },
    //     });
    //   });
    // };
  },
  methods: {
    playing(e) {
      if (this.isPlaying) {
        this.isPlaying.stopVideo();
      }
      this.isPlaying = e;
    },
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/media.pcss';
@import '~/assets/vars.pcss';

.VideoList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  grid-auto-flow: dense;

  @media (--medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--big) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.VideoItem {
  position: relative;
}

.VideoTitle {
  position: absolute;
  left: $main-padding;
  top: $main-padding;
  z-index: 1;
  color: $white;
  text-transform: uppercase;
}

.LoadingWrapper svg {
  max-height: 200px;
  margin-bottom: $main-padding;
}
</style>

<style lang="postcss">
@import '~/assets/vars.pcss';

iframe {
  width: 100%;
  max-width: 650px; /* Also helpful. Optional. */
  border-radius: 10px;
}
</style>
