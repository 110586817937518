<template>
  <div class="GoogleWidget">
    <div v-if="isFetching" class="LoadingWrapper">
      <vcl-facebook />
      <vcl-facebook />
      <vcl-facebook />
    </div>
    <ul v-if="!isFetching" class="NewsList">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="`GoogleNewsItem L${index}`"
      >
        <a
          :href="item.link"
          target="_blank"
          rel="noopener"
          class="NewsContainer"
        >
          <v-lazy-image
            class="NewsImage"
            src-placeholder="/assets/images/CnewsMonkey.svg"
            :error="'/assets/images/CnewsMonkey.svg'"
            :src="computeImage(item.image)"
            :alt="item.title"
          />
          <div class="NewsContent">
            <h4 class="NewsTitle">
              {{ item.title }}
            </h4>
            <div class="NewsText">{{ item.content }}</div>
            <div class="NewsDetails">
              <span class="NewsAuthor">
                {{ item.author.replace(/(http|https):\/\/(www\.|)/gi, '') }}
              </span>
              <span class="NewsDate">
                <img
                  class="IconClock"
                  src="/assets/icons/clock.svg"
                  alt="Icona orologio"
                />
                <span class="NewsDate">
                  {{ formatToNow(item.date) }}
                </span>
              </span>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { VclFacebook } from 'vue-content-loading';
import { formatToNow } from '~/lib/date';
import { fetchGoogleNews } from '~/plugins/api-ondemand';

export default {
  components: {
    VclFacebook,
  },
  data() {
    return {
      isFetching: false,
      items: [],
    };
  },
  async beforeCreate() {
    this.isFetching = true;
    const { items } = await fetchGoogleNews(this.$axios);
    this.items = items;
    this.isFetching = false;
  },
  methods: {
    formatToNow,
    computeImage(image) {
      const currImage = image && !image.includes('ragusah24') ? image : null;
      return currImage || '/assets/images/CnewsMonkey.svg';
    },
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';
@import '~/assets/media.pcss';
@import '~/assets/typography.pcss';

.GoogleNewsItem {
  margin: $main-padding 0;
  padding: $main-padding;
  border-radius: 10px;
  box-shadow: $shadow-1;
}

.LoadingWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  grid-auto-flow: dense;

  & svg {
    margin-bottom: $main-padding;
  }

  @media (--mid-small) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (--medium) {
    grid-template-columns: repeat(3, 1fr);

    & svg {
      max-height: 180px;
    }
  }
}

.NewsContainer {
  display: flex;
  flex-direction: column;

  .NewsTitle {
    margin-bottom: 10px;
  }

  .NewsText {
    @mixin paragraph;
    margin-bottom: 5px;
    word-break: break-word;
  }

  .NewsDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .NewsDate {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .NewsImage {
    max-width: 180px;
    max-height: 100%;
    border-radius: 10px;
    margin-right: $main-padding;
    margin-bottom: 10px;
    align-self: center;
  }

  .NewsContent {
    width: 100%;
  }

  .IconClock {
    margin-right: 5px;
  }

  ul {
    list-style: none;
  }
}

@media (--mid-small) {
  .NewsContainer {
    flex-direction: row;
    align-items: center;

    .NewsImage {
      margin-bottom: 0;
    }
  }
}
</style>
