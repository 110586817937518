import { render, staticRenderFns } from "./YouTubeWidget.vue?vue&type=template&id=3a20ddc6&scoped=true&"
import script from "./YouTubeWidget.vue?vue&type=script&lang=js&"
export * from "./YouTubeWidget.vue?vue&type=script&lang=js&"
import style0 from "./YouTubeWidget.vue?vue&type=style&index=0&id=3a20ddc6&lang=postcss&scoped=true&"
import style1 from "./YouTubeWidget.vue?vue&type=style&index=1&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a20ddc6",
  null
  
)

export default component.exports