import { formatInstagramDate, formatFlickrDate } from '~/lib/date';

const API_URL = 'https://on-demand.comisonews.it';

export const fetchInstagram = async (axios) => {
  const { data } = await axios(API_URL + '/instagram');
  return {
    items: data.items.map((item) =>
      item.type === 'video'
        ? {
            html: `<div class="wrapper"><div class="video-wrapper"><video class="pswp__video" src="${item.video}" controls></video></div></div><div>`,
            thumbnail: item.thumbnail,
            title: `${item.likeCount} da ${
              item.username
            } alle ${formatInstagramDate(item.createdTime)}`,
          }
        : {
            src: item.src,
            thumbnail: item.thumbnail,
            w: 200,
            h: 200,
            title: `${item.likeCount} da ${
              item.username
            } alle ${formatInstagramDate(item.createdTime)}`,
          }
    ),
  };
};

export const fetchFlickr = async (axios) => {
  const { data } = await axios(`${API_URL}/flickr`);
  return {
    items: data.items.map((item) => ({
      src: item.src,
      thumbnail: item.thumbnail,
      w: 200,
      h: 200,
      title: `"${item.caption}" da ${item.username} il ${formatFlickrDate(
        item.createdTime
      )}`,
    })),
  };
};

export const fetchTwitter = async (axios) => {
  const { data } = await axios(`${API_URL}/twitter`);
  return { items: data.items };
};

export const fetchYoutube = async (axios) => {
  const { data } = await axios(`${API_URL}/youtube`);
  return { items: data.items };
};

export const fetchGoogleNews = async (axios) => {
  const { data } = await axios(`${API_URL}/google`);
  return { items: data.items };
};
