<template>
  <div>
    <main class="Main">
      <section class="MainArticles">
        <article-block
          v-for="item in items"
          :key="item.id"
          :is-fetching="mainLoading"
          :article="item"
          class="ArticleBlock"
        />
      </section>
      <client-only>
        <section class="ComisoSocial">
          <div class="ComisoGoogleNews">
            <h2>Comiso su Google News</h2>
            <GoogleWidget />
          </div>
          <div class="ComisoYouTube">
            <h2>Comiso su YouTube</h2>
            <YouTubeWidget
              :items="youtubeItems"
              :is-fetching="youtubeLoading"
            />
          </div>
          <div class="Twitter">
            <h2>Comiso su Twitter</h2>
            <twitter-timeline
              :is-fetching="twitterLoading"
              :items="twitterItems"
            />
          </div>
          <div class="ComisoInstagram">
            <h2>Comiso su Instagram</h2>
            <vue-picture-swipe
              class="InstagramGallery"
              :is-fetching="instagramLoading"
              :items="instagramItems"
            />
          </div>
          <div class="ComisoFlickr">
            <h2>Comiso su Flickr</h2>
            <vue-picture-swipe
              class="FlickrGallery"
              :is-fetching="flickrLoading"
              :items="flickrItems"
            />
          </div>
        </section>
      </client-only>
    </main>
  </div>
</template>

<script>
import { fetchMainNews } from '~/plugins/sanity';
import {
  fetchTwitter,
  fetchInstagram,
  fetchFlickr,
  fetchYoutube,
} from '~/plugins/api-ondemand';
import ArticleBlock from '~/components/ArticleBlock.vue';
import TwitterTimeline from '~/components/TwitterTimeline.vue';
import VuePictureSwipe from '~/components/VuePictureSwipe.vue';
import YouTubeWidget from '~/components/YouTubeWidget.vue';
import GoogleWidget from '~/components/GoogleWidget.vue';

export default {
  components: {
    ArticleBlock,
    TwitterTimeline,
    VuePictureSwipe,
    YouTubeWidget,
    GoogleWidget,
  },
  asyncData({ error, store }) {
    store.commit('updateMainArticleLoading', true);
    return fetchMainNews()
      .then((res) => ({ items: res }))
      .catch(error)
      .finally(() => store.commit('updateMainArticleLoading', false));
  },
  data() {
    return {
      youtubeItems: [],
      twitterItems: [],
      instagramItems: [],
      flickrItems: [],
    };
  },
  head() {
    return {
      title: 'Comiso News - Tutte le notizie su Comiso in un solo sito',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          hid: 'description',
          name: 'description',
          content:
            'Comiso News è un sito che raccoglie tutte le notizie presenti su Internet che riguardano la città di Comiso (RG) in tempo reale. Su Comiso News trovi anche tutti post sei social network, le foto e i video su Comiso. Tutto in tempo reale e su un solo sito.',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content:
            'Comiso, News, Notizie Comiso, Ragusa, Provincia di Ragusa, Aeroporto di Comiso, Politica Comiso, Cronaca Comiso, Cultura Comiso, Sport Comiso, Ultime notizie Comiso, Google News Comiso, YouTube Comiso, Flickr Comiso, Instagram Comiso, Twitter Comiso, Facebook Comiso',
        },
        { name: 'msapplication-TileColor', content: '#da532c' },
        {
          hid: 'theme-color',
          name: 'theme-color',
          content: '#009DFF',
        },
        {
          hid: 'mobile-web-app-capable',
          name: 'mobile-web-app-capable',
          content: 'yes',
        },
        {
          hid: 'application-name',
          name: 'application-name',
          content: 'Comiso News - Tutte le notizie su Comiso in un solo sito',
        },
        {
          hid: 'apple-mobile-web-app-capable',
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          hid: 'apple-mobile-web-app-status-bar-style',
          name: 'apple-mobile-web-app-status-bar-style',
          content: '#009DFF',
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: 'Comiso News - Tutte le notizie su Comiso in un solo sito',
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary',
        },
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          content: '@comisonews',
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'Comiso News - Tutte le notizie su Comiso in un solo sito',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${process.env.BASE_URL}/sharer-image.png`,
        },
      ],
    };
  },
  computed: {
    mainLoading() {
      return this.$store.state.mainArticleLoading;
    },
    youtubeLoading() {
      return this.$store.state.youtubeLoading;
    },
    twitterLoading() {
      return this.$store.state.twitterLoading;
    },
    instagramLoading() {
      return this.$store.state.instagramLoading;
    },
    flickrLoading() {
      return this.$store.state.flickrLoading;
    },
  },
  async created() {
    this.$store.commit('updateTwitterLoading', true);
    this.$store.commit('updateYoutubeLoading', true);
    this.$store.commit('updateInstagramLoading', true);
    this.$store.commit('updateFlickrLoading', true);

    fetchYoutube(this.$axios).then(({ items }) => {
      this.youtubeItems = items;
      this.$store.commit('updateYoutubeLoading', false);
    });

    fetchTwitter(this.$axios).then(({ items }) => {
      this.twitterItems = items;
      this.$store.commit('updateTwitterLoading', false);
    });

    fetchInstagram(this.$axios).then(({ items }) => {
      this.instagramItems = items;
      this.$store.commit('updateInstagramLoading', false);
    });

    fetchFlickr(this.$axios).then(({ items }) => {
      this.flickrItems = items;
      this.$store.commit('updateFlickrLoading', false);
    });
  },
};
</script>

<style lang="postcss" scoped>
@import '~/assets/vars.pcss';
@import '~/assets/mixins.pcss';

img {
  max-width: 100%;
  height: 80px;
}

.Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  user-select: none;
  cursor: default;

  & > a {
    width: 100%;
  }
}

.ArticleBlock {
  margin: 20px;
}

.MainArticles {
  width: 100%;
}

.ComisoSocial {
  width: 100%;
  padding: $main-padding;

  & > div:not(:last-child) {
    margin-bottom: 30px;
  }
}

.ComisoGoogle {
  padding: $main-padding;
}

.InstagramGallery,
.FlickrGallery {
  background-color: $soft-background;
  padding: 10px;
  border-radius: 10px;
}
</style>
